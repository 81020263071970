import { http } from '@/api/requests/httpAxios'
import { arrayBufferToBlob } from 'blob-util'
import { saveAs } from 'file-saver'
import moment from 'moment'
import dateFormat from '@/config/default/dateFormat'
import createURLParams from '@/api/datasource/createURLParams'

export default class ExcelAPI {
  public static async getCourseResultsByStudent(
    userId: number,
    courseId: number
  ): Promise<void> {
    const params = new URLSearchParams()
    params.append('userId', userId.toString())
    params.append('courseId', courseId.toString())
    const response = await http.get('/excel/results/by-student/course', {
      params,
      responseType: 'arraybuffer'
    })
    saveAs(
      arrayBufferToBlob(response.data),
      `${moment().format(dateFormat.date.inputFormat)}_result_of_student.xlsx`
    )
  }
  public static async getCourseResultsByCohort(
    organizationId: number,
    courseId: number
  ): Promise<void> {
    const params = new URLSearchParams()
    params.append('organizationId', organizationId.toString())
    params.append('courseId', courseId.toString())
    const response = await http.get('/excel/results/by-cohort/course', {
      params,
      responseType: 'arraybuffer'
    })
    saveAs(
      arrayBufferToBlob(response.data),
      `${moment().format(
        dateFormat.date.inputFormat
      )}_result_of_organization.xlsx`
    )
  }
  public static async getAllResultsByCohort(
    organizationId: number
  ): Promise<void> {
    const params = new URLSearchParams()
    params.append('organizationId', organizationId.toString())
    const response = await http.get('/excel/results/by-cohort/all', {
      params,
      responseType: 'arraybuffer'
    })
    saveAs(
      arrayBufferToBlob(response.data),
      `${moment().format(
        dateFormat.date.inputFormat
      )}_all_results_of_organization.xlsx`
    )
  }
  public static async getAllResults(): Promise<void> {
    const params = new URLSearchParams()
    const response = await http.get('/excel/results/all', {
      params,
      responseType: 'arraybuffer'
    })
    saveAs(
      arrayBufferToBlob(response.data),
      `${moment().format(dateFormat.date.inputFormat)}_all_results.xlsx`
    )
  }
  public static async getStudentPortfolio(userId: number): Promise<void> {
    const params = new URLSearchParams()
    params.append('userId', userId.toString())
    const response = await http.get('/excel/portfolio', {
      params,
      responseType: 'arraybuffer'
    })
    saveAs(
      arrayBufferToBlob(response.data),
      `${moment().format(dateFormat.date.inputFormat)}_portfolio.xlsx`
    )
  }
  static async exportUsersExcel(
    filter: { [k: string]: any } | null,
    orgTitle: null | string
  ) {
    const params = createURLParams(0, 0, null, { filter })
    const response = await http.get(`/xlsx/admin/users`, {
      params,
      responseType: 'arraybuffer'
    })
    const prefix = orgTitle ? `ученики ${orgTitle}` : 'пользователи'
    const now = moment().format(dateFormat.date.localFormat)
    const fileName = `${prefix}-${now}.xlsx`
    saveAs(arrayBufferToBlob(response.data), fileName)
  }

  static async exportVotingExcel(content: any) {
    const response = await http.get(`/voting/xlsx/${content.id}`, {
      responseType: 'arraybuffer'
    })
    const now = moment().format(dateFormat.date.localFormat)
    const fileName = `${content.title.replace(' ', '_')}-${now}-voteData.xlsx`
    saveAs(arrayBufferToBlob(response.data), fileName)
  }
}
