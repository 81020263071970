

























import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { FormSchema } from 'vue-form-generator'
import { isEmpty, isNil, uniq } from 'lodash'
import UserFactory from '@/factories/user'
import AdminAPI from '@/api/requests/admin'
import { dictMapper } from '@/store/modules/dicts'
import { toastMapper } from '@/store/modules/toast'
import { regMail } from '@/config/default/regex'
import { inviteUserModal } from '@/config/types/inviteUserModal'

const Mapper = Vue.extend({
  computed: {
    ...dictMapper.mapGetters(['organizations'])
  },
  methods: {
    ...dictMapper.mapActions(['fetchAllOrganizations']),
    ...toastMapper.mapActions(['pushToast'])
  }
})

@Component
export default class InviteStudentsModal extends Mapper {
  @Prop(String) modalId!: string
  @Prop(Object) organization!: object
  @Prop(String) type!: inviteUserModal

  private userInviteModel = UserFactory.emptyInviteUserModel()
  private isStaticOrganization = false
  private userInviteSchema: FormSchema = {
    fields: [
      {
        type: 'multiselect',
        label: 'Организация',
        model: 'organizations',
        placeholder: 'Выберите организацию',
        styleClasses: 'mt-1',
        disabled: !!this.organizations,
        options: [],
        trackBy: 'id',
        multiple: true,
        required: true,
        customLabel({ title }: any) {
          return title ? title : 'Выберите значение'
        }
      },
      {
        type: 'textArea',
        model: 'emailString',
        placeholder: `Введите список e-mail-ов ${this.placeholder}, разделенных ';'`,
        rows: 4
      }
    ]
  }

  private created() {
    if (
      this.type === inviteUserModal.inviteStudents ||
      this.type === inviteUserModal.inviteTeachers
    ) {
      this.onOrganizationChange()
      this.userInviteSchema.fields!.find(
        field => (field.model = 'organizations')
      )!.visible = false
      this.isStaticOrganization = true
    } else if (this.type === inviteUserModal.addStudents) {
      this.getOrganizations()
    }
  }

  @Watch('organization')
  private onOrganizationChange() {
    if (isNil(this.organization)) return
    this.userInviteModel.organizations = [this.organization]
  }

  private async getOrganizations() {
    await this.fetchAllOrganizations()
    this.userInviteSchema.fields!.find(
      item => item.model === 'organizations'
    )!.options = this.organizations
  }

  get placeholder() {
    return this.type === inviteUserModal.inviteTeachers
      ? 'классных руководителей'
      : 'учеников'
  }

  get isEditMode() {
    return (
      this.userInviteModel.emailString &&
      !isEmpty(this.userInviteModel.organizations)
    )
  }

  private onHidden() {
    if (!this.organization) {
      this.userInviteModel = UserFactory.emptyInviteUserModel()
    } else {
      this.userInviteModel.emails = UserFactory.emptyInviteUserModel().emails
    }
  }

  private checkEmails(emails: string[]) {
    for (const email of emails) {
      if (!regMail.test(email)) {
        return false
      }
    }
    return true
  }

  private onSave() {
    if (this.isStaticOrganization) {
      this.onOrganizationChange()
    }
    const usersWithEmails = uniq(
      (this.userInviteModel!.emailString as string)
        .split(';')
        .map((email: string) => email.trim())
    )
    if (this.checkEmails(usersWithEmails)) {
      this.userInviteModel!.emails = usersWithEmails
      delete this.userInviteModel.emailString

      const userOrganizations = this.userInviteModel.organizations
      this.userInviteModel.organizations = userOrganizations.map(org => org.id)

      this.userInviteModel.emails = this.userInviteModel.emails?.map(email =>
        email.toLowerCase()
      )
      AdminAPI.inviteUserByRole(
        this.userInviteModel,
        this.type === inviteUserModal.inviteTeachers ? 'teacher' : 'student'
      )
      this.$bvModal.hide(this.modalId)
    } else {
      this.pushToast({
        error: true,
        time: 5,
        title: 'Ошибка ввода',
        message: 'Проверьте правильность ввода email-ов'
      })
    }
  }
}
